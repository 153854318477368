<template>
    <!-- begin:: Header Topbar -->
    <div class="topbar">
        <!--begin: Search -->
            <b-dropdown
              size="sm"
              id="kt_quick_search_toggle"
              variant="link"
              toggle-class="topbar-item text-decoration-none"
              no-caret
              right
              no-flip
              ref="search"
            >
              <template v-slot:button-content>
                <div class="btn btn-icon  btn-lg btn-dropdown mr-1">
                  <span @click="setFocus" ref="mySearch" class="svg-icon svg-icon-xl svg-icon-primary">
                        <i class="menu-icon fa fa-search"><span></span></i>
                  </span>
                </div>
              </template>
              <b-dropdown-text tag="div" class="min-w-md-350px">
                <KTSearchDefault ref="search-focus" :triggerSearchBox="triggerSearchBox"></KTSearchDefault>
              </b-dropdown-text>
            </b-dropdown>

        <b-dropdown
                size="sm"
                variant="link"
                toggle-class="topbar-item text-decoration-none"
                no-caret
                right
                no-flip
        >
            <template v-slot:button-content >
                <div
                        @click="callNotification()"
                        class="btn btn-icon  btn-dropdown btn-lg mr-1 pulse pulse-primary"
                >
          <span class="svg-icon svg-icon-xl svg-icon-primary">
                <i class="menu-icon far fa-bell"><span></span></i>
          </span>
<!--                    <span class="pulse-ring"></span> -->
                    <span v-if="notificationsCount > 0" class="badge badge-primary rounded-pill badge-sm px-2 py-1 mx-1 count-badge-nav">{{notificationsCount}}</span>
                </div>
            </template>
            <b-dropdown-text tag="div" class="min-w-md-350px">
                <form>
                    <KTDropdownNotification @refresh="getAdminNotificationsCount"></KTDropdownNotification>
                </form>
            </b-dropdown-text>
        </b-dropdown>
        <!--end: Notifications -->
        <!--begin: User Bar -->
        <KTQuickUser></KTQuickUser>
        <!--end: User Bar -->
    </div>
    <!-- end:: Header Topbar -->
</template>

<style lang="scss">
    .topbar {
        .dropdown-toggle {
            padding: 0;

            &:hover {
                text-decoration: none;
            }

            &.dropdown-toggle-no-caret {
                &:after {
                    content: none;
                }
            }
        }

        .dropdown-menu {
            margin: 0;
            padding: 0;
            outline: none;

            .b-dropdown-text {
                padding: 0;
            }
        }
    }
</style>

<script>
    import KTSearchDefault from "@/view/layout/extras/dropdown/SearchDefault.vue";
    import KTDropdownMyCart from "@/view/layout/extras/dropdown/DropdownMyCart.vue";
    import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
    import i18nService from "@/core/services/i18n.service.js";
    import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
    import UserNotificationService from "@/services/user-notification/UserNotificationService";

    const userNotification = new UserNotificationService();

    export default {
        name: "KTTopbar",
        data() {
            return {
                languageFlag: "",
                languages: i18nService.languages,
                notificationsCount: 0,
            };
        },
        components: {
            KTDropdownNotification,
            KTSearchDefault,
            KTDropdownMyCart,
            KTQuickUser,
        },
        mounted() {
            this.initialNotificationCount();
        },
        methods: {

            callNotification() {
                this.initialNotificationCount();
                this.$bus.emit('checkNotification');
            },
            onLanguageChanged() {
                this.languageFlag = this.languages.find(val => {
                    return val.lang === i18nService.getActiveLanguage();
                }).flag;
            },
            initialNotificationCount(){
                userNotification
                .getAdminNotificationsCount(this.currentUser.id)
                .then((response) => {
                    this.notificationsCount = response.data;
                    this.getAdminNotificationsCount();
                    this.$emit('refresh');
                })
                .catch((err) => {

                });
            },
            getAdminNotificationsCount(){
                setInterval(() => {
                    userNotification
                    .getAdminNotificationsCount(this.currentUser.id)
                    .then((response) => {
                        this.notificationsCount = response.data;
                    })
                    .catch((err) => {

                    });
                }, 100000);
            },
          setFocus(){
            this.$refs['search-focus'].setFocus();
          },
          triggerSearchBox() {
            this.$refs.mySearch.click();
          }
        },
        computed: {
            getLanguageFlag() {
                return this.onLanguageChanged();
            },
            user() {
                return this.$store.getters.currentUser;
            },
            currentUser() {
                return this.$store.getters.currentUser;
            },
        }
    };
</script>

<template>
  <ul class="menu-nav">
    <router-link to="/dashboard" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>
    <router-link :to="{ name: 'manual-enrolment-step-1' }" v-slot="{ href, navigate, isActive, isExactActive }" title="Manual enrolment">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate" title="Manual enrolment">
          <i class="menu-icon fa-solid fa-laptop-file"><span></span></i>
          <span class="menu-text">Manual enrolment</span>
        </a>
      </li>
    </router-link>
    <router-link v-if="checkIsAccessible('account_holder')" :to="{ name: 'accountholder' }" v-slot="{ href, navigate, isActive, isExactActive }" title="Add enroller">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate" title="Add enroller">
          <i class="menu-icon fas fa-user"><span></span></i>
          <span class="menu-text">Add enroller</span>
        </a>
      </li>
    </router-link>
      <router-link to="/merge/account-holders" v-slot="{ href, navigate, isActive, isExactActive }" title="Merge">
          <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate" title="Merge">
                  <i class="menu-icon fas fa-code-merge"></i>
                  <span class="menu-text">Transfer enrolment</span>
              </a>
          </li>
      </router-link>
    <router-link :to="{name:'exam-day'}" v-slot="{ href, navigate, isActive, isExactActive }" title="Schedules">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate"  title="Schedules">
          <i class="menu-icon fa-solid fa-business-time"><span></span></i>
          <span class="menu-text">Schedules</span>
        </a>
      </li>
    </router-link>
    <router-link :to="{name:'pending-print'}" v-slot="{ href, navigate, isActive, isExactActive }" title="Printing">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate"  title="Printing">
          <i class="menu-icon fa-solid fa-print"><span></span></i>
          <span class="menu-text">Printing</span>
        </a>
      </li>
    </router-link>
    <router-link :to="{name:'pplate-piano-print'}" v-slot="{ href, navigate, isActive, isExactActive }" title="Printing">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate"  title="Printing">
          <i class="menu-icon fa-solid fa-print"><span></span></i>
          <span class="menu-text">P Plate piano printing</span>
        </a>
      </li>
    </router-link>
    <router-link :to="{ name: 'support-ticket', params: { type: 'all' } }" v-slot="{ href, navigate, isActive, isExactActive }" title="Tickets">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link"  title="Tickets">
          <i class="menu-icon fa-solid fa-ticket"><span></span></i>
          <span class="menu-text">Tickets <span class="badge badge-square badge-primary ml-1 count-badge-nav">{{ unReadTicketAll }}</span></span>
        </a>
      </li>
    </router-link>
    <router-link :to="{ name: 'support-ticket', params: { type: 'refund' } }" v-slot="{ href, navigate, isActive, isExactActive }"  title="Refunds">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" title="Refunds">
          <i class="menu-icon fa-solid fa-hand-holding-dollar"><span></span></i>
          <span class="menu-text">Refunds<span class="badge badge-square badge-primary ml-1 count-badge-nav ">{{ unReadTicketRefund }}</span></span>
        </a>
      </li>
    </router-link>
    <router-link v-if="appEnv == 'local'" :to="{ name: 'schedules-calendar' }" v-slot="{ href, navigate, isActive, isExactActive }"  title="Refunds">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" title="Schedules calendar">
          <i class="menu-icon fa-solid fa-hand-holding-dollar"><span></span></i>
          <span class="menu-text">Schedules calendar</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>
<script>
import SupportTicketService from "@/services/support/support-ticket/SupportTicketService";
import {
  SET_SUPPORT
} from "@/core/services/store/support.module";

const supportTicket=new SupportTicketService();
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    isAdmin() {
      return this.currentUser.type === "admin" ? true : false;
    },
    unReadTicketAll() {
      return this.$store.getters.currentSupport;
    },
    // unReadTicketGeneral() {
    //   return this.$store.getters.currentGeneral;
    // },
    // unReadTicketExtension() {
    //   return this.$store.getters.currentExtension;
    // },
    unReadTicketRefund() {
      return this.$store.getters.currentRefund;
    },
    unReadTicketPayment() {
      return this.$store.getters.currentPayment;
    },
    // unReadTicketAppeal() {
    //   return this.$store.getters.currentAppeal;
    // },
    // unReadTicketFeedback() {
    //   return this.$store.getters.currentFeedback;
    // },
    unReadTicketShipment() {
      return this.$store.getters.currentShipment;
    },
    unReadTicketOther() {
      return this.$store.getters.currentOther;
    },
    schoolPayApplicationsCount(){
      return this.$store.getters.countSchoolPayApplications;
    },
    appEnv() {
      return process.env.VUE_APP_ENV;
    },
  },
  mounted() {
    this.$store.dispatch(SET_SUPPORT);
  }
};
</script>

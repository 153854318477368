var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex flex-column pt-2 bgi-size-cover bgi-no-repeat rounded-top"},[_vm._m(0),_c('div',{staticClass:"ml-0 mr-0 p-0"},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"list-item@4"}}):_vm._e(),(!_vm.loading)?_c('perfect-scrollbar',{staticClass:"scroll pr-2 pl-2 mr-n0",staticStyle:{"max-height":"40vh","position":"relative"}},[_vm._l((_vm.notifications),function(item,i){return (_vm.notifications.length > 0)?[_c('div',{key:i,class:'d-flex align-items-center mb-1 border-bottom notification-unread '},[_c('div',{staticClass:"symbol symbol-40 mr-2",class:`symbol-light-${
                item.status == 'pending'
                  ? 'warning'
                  : item.status == 'ready'
                  ? 'primary'
                  : 'success'
              }`},[_c('span',{staticClass:"symbol-label"},[_c('span',{staticClass:"svg-icon svg-icon-lg",class:`symbol-light-${
                    item.status == 'pending'
                      ? 'warning'
                      : item.status == 'ready'
                      ? 'primary'
                      : 'success'
                  }`},[(item.type == 'export' || item.type == 'import')?_c('i',{staticClass:"fa fa-file-csv"}):(item.type == 'print')?_c('i',{staticClass:"fa fa-print"}):_c('i',{staticClass:"fas fa-info"})])])]),_c('div',{staticClass:"d-flex flex-column",class:!item.is_viewed ? 'font-unread': 'font-read'},[_c('a',{staticClass:"text-dark text-hover-primary mb-1 font-size-md",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.viewNotification(item, item.type)}}},[_vm._v(" "+_vm._s(item.title)+" "),_c('br'),_c('small',[_vm._v(_vm._s(item.formatted_date_sent)+" "+_vm._s(item.formatted_time))])]),_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(item.formatted_date_sent)+" - "+_vm._s(item.formatted_time)+" "+_vm._s(item.status)+" ")])])])]:_vm._e()})],2):_vm._e(),_c('div',{staticClass:"d-flex flex-column item- font-weight-bold pt-4 pl-2"},[_c('router-link',{staticClass:"text-dark text-hover-primary mb-1 font-size-lg ml-1 mb-5",attrs:{"to":{ name: 'notifications' }}},[_vm._v(" View all ")])],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"rounded-top border-bottom px-2 pb-2"},[_c('span',{},[_vm._v(" Notifications ")])])
}]

export { render, staticRenderFns }
<template>
  <div>
    <div
      class="d-flex flex-column pt-2 bgi-size-cover bgi-no-repeat rounded-top"
    >
      <h4 class="rounded-top border-bottom px-2 pb-2">
        <span class="">
          Notifications
        </span>
      </h4>
      <div class="ml-0 mr-0 p-0">
          <v-skeleton-loader
                  v-if="loading"
                  type="list-item@4"
          >
          </v-skeleton-loader>
        <perfect-scrollbar v-if="!loading"
          class="scroll pr-2 pl-2 mr-n0"
          style="max-height: 40vh; position: relative"
        >
          <template 
            v-if="notifications.length > 0" 
            v-for="(item, i) in notifications"
          >

            <!--begin::Item-->
            <div
              :class="'d-flex align-items-center mb-1 border-bottom notification-unread '"
              v-bind:key="i"
            >
              <!--begin::Symbol-->
              <div
                class="symbol symbol-40 mr-2"
                v-bind:class="`symbol-light-${
                  item.status == 'pending'
                    ? 'warning'
                    : item.status == 'ready'
                    ? 'primary'
                    : 'success'
                }`"
              >
                <span class="symbol-label">
                  <span
                    class="svg-icon svg-icon-lg"
                    v-bind:class="`symbol-light-${
                      item.status == 'pending'
                        ? 'warning'
                        : item.status == 'ready'
                        ? 'primary'
                        : 'success'
                    }`"
                  >
                    <!--begin::Svg Icon-->
                    <i v-if="item.type == 'export' || item.type == 'import'" class="fa fa-file-csv"></i>
                    <i v-else-if="item.type == 'print'" class="fa fa-print"></i>
                    <i v-else class="fas fa-info"></i>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column"  :class="!item.is_viewed ? 'font-unread': 'font-read'">
                <a
                  @click.prevent="viewNotification(item, item.type)"
                  href="#"
                  class="text-dark text-hover-primary mb-1 font-size-md"
                >
                  {{ item.title }} <br>
                  <small>{{item.formatted_date_sent}} {{item.formatted_time}}</small>

                </a>
                <span class="text-muted">
                 {{item.formatted_date_sent}} - {{item.formatted_time}} {{ item.status }}
                </span>
              </div>

              <!--end::Text-->
            </div>
            <!--end::Item-->
          </template>
          

        </perfect-scrollbar>
          <div
                  class="d-flex flex-column item- font-weight-bold pt-4 pl-2"
          >
              <router-link
                      :to="{ name: 'notifications' }"
                      class="text-dark text-hover-primary mb-1 font-size-lg ml-1 mb-5"
              >
                  View all
              </router-link>
          </div>

      </div>
    </div>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import { API_URL } from "../../../../common/config";
import UserNotificationService from "@/services/user-notification/UserNotificationService";

const userNotification = new UserNotificationService();

export default {
  name: "KTDropdownNotification",
  data() {
    return {
      tabIndex: 0,
        loading: true,
      notifications: [],
      currentUser: {}
    };
  },
  created() {
    this.$bus.on("checkNotification", () => {
      this.getCurrentUser();
      this.getAdminNotifications();
    });
  },
  methods: {
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    getAdminNotifications() {
      this.loading = true
      userNotification
        .getAdminNotifications(this.currentUser.id)
        .then((response) => {
          this.notifications = [];
          this.notifications = response.data.data;
          this.loading = false
        })
        .catch((err) => {
            this.loading = false
        });
    },
    
    // viewNotification(notification) {
    //   if (notification.status != "pending") {
    //     this.getNotifications();
    //     window.open(`${API_URL}notification/view/${notification.id}`, "_blank");
    //   } else {
    //     notification.is_viewed = true;
    //   }
    // },
    
    viewNotification(item, type){
      if(type == 'print'){
        this.$router.push({
          name: "print-job-queue",
          params: {userNotificationId: item.id}
        });
      }
      else if(type == 'note'){
        if(!item.is_viewed) {
          userNotification
              .markAsViewed(item.id);
        }


        if(item.candidate_id && item.candidate_exam_key) {
          this.$tabs.open('/candidate/summary/'+item.candidate_exam_key);
        }
        else {
          this.$tabs.open({
            name: "accountholder-edit",
            params: { accountholderId: item.user_id, tabIndex: 1 }
          });
        }
      }
      else{
        userNotification
        .readNotification(item.id);
      }
      this.$emit('refresh');
      this.getAdminNotifications();
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    },
    // currentUser() {
    //   return this.$store.getters.currentUser;
    // },
  },
};
</script>

<style scoped>
.read {
  background-color: #ffffff;
}
</style>
